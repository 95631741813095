import axios from "axios";

const patchUserObject = (userObject) => {
  const baseRoute = "https://bucket-pasta-server-piwii.ondigitalocean.app";
  // const baseRoute = "http://localhost:9091";
  const token = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${token}` };
  const lastPushed = new Date(userObject.updatedAt).toISOString();
  userObject.lastPushed = lastPushed;
  // const tempUserData = JSON.parse(localStorage.getItem("userObject"));
  // console.log(JSON.parse(tempUserData));
  axios
    .patch(`${baseRoute}/users/`, userObject, { headers })
    .then((response) => {
      // console.log(response.data);
      //   console.log
      return;
    });
};

export default patchUserObject;
