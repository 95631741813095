import axios from "axios";

async function getLastUpdated() {
  const baseRoute = "https://bucket-pasta-server-piwii.ondigitalocean.app";
//   const baseRoute = "http://localhost:9091"
  const token = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${token}` };
  const data = await axios
    .get(`${baseRoute}/users/lastUpdated`, { headers })
    .catch((err) => {
      console.error(err);
      return null;
    });
    // console.log(data)
  return data.data.truncatedObject//.truncatedObject;
}

export default getLastUpdated;
