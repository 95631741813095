import getUserObject from "./getUserObject";
import patchUserObject from "./patchUserObject";
import getLastUpdated from "./getLastUpdated";

async function autosave(userObject, setUserObject) {
  // console.log("autosave ran");
  // const remoteUserObject = await getUserObject();
  const truncatedObject = await getLastUpdated();
  console.log(truncatedObject)
  if (truncatedObject) {
    const remoteUpdatedAt =
      new Date(truncatedObject.lastPushed).getTime() || new Date(0).getTime();
    const localUpdatedAt = new Date(userObject.updatedAt).getTime();
    // console.log({ remoteUpdatedAt, localUpdatedAt });
    // console.log({ userObject, remoteUserObject });
    //   console.log({ remoteUserObject, userObject });
    if (localUpdatedAt > remoteUpdatedAt) {
      console.log("Updating remote with local data");
      userObject.lastPushed = userObject.updatedAt;
      patchUserObject(userObject);
    } else if (
      localUpdatedAt < remoteUpdatedAt ||
      // !remoteUserObject.tabs.length ||
      !userObject.tabs.length
    ) {
      console.log("updating local with remote data");
      const remoteUserObject = await getUserObject();
      remoteUserObject.updatedAt = remoteUserObject.lastPushed;
      setUserObject(remoteUserObject);
    }
  }
}

export default autosave;
