import axios from "axios";

async function getUserObject() {
  const baseRoute = "https://bucket-pasta-server-piwii.ondigitalocean.app";
  const token = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${token}` };
  const data = await axios
    .get(`${baseRoute}/users`, { headers })
    .catch((err) => {
      console.error(err);
      return null;
    });
  return data.data.userObject;
}

export default getUserObject;
