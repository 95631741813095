import axios from "axios";
import signin from "./signin";

const signup = (email, password) => {
    const baseRoute = "https://bucket-pasta-server-piwii.ondigitalocean.app";
    const data = { username: email, password: password };
    axios.post(`${baseRoute}/signup`, data).then((response) => {
      signin(email, password);
    });
  };

export default signup