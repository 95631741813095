import React, { useState, useEffect } from "react";
import emptyUserObject from "../resources/emptyUserObject.json";
import newUserObject from "../resources/newUserObject.json";
import emptyTab from "../resources/emptyTab.json";
import loadUserData from "../interfaces/loadInterfaces/load.js";
import saveUserData from "../interfaces/saveInterfaces/save.js";
import Tabs from "../components/Tabs";
import LoadfileThingie from "./loadfile.jsx";
import "./Main.scss";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "../components/AppBar.jsx";
import ModalPastyForm from "./modalPastyForm";
import autosave from "../interfaces/autosave";
import searchFilterUserObject from "../interfaces/searchFilterUserObject";

// import Tab from "./tab.jsx";
let userName = "";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const Main = () => {
  if (!localStorage.token) {
    window.location = "/login";
  }
  // emptyUserObject is to make sure tabs is available when component mounts
  const [userObject, setUserObjectBase] = useState({ ...emptyUserObject });
  const [displayObject, setDisplayObject] = useState(userObject);
  const [hasGetRun, setHasGetRun] = useState(false);
  const [online, setOnline] = useState(false);
  const [pastyBeingEdited, setPastyBeingEdited] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingPastyIdx, setEditingPastyIdx] = useState(null);
  const [editingTabIdx, setEditingTabIdx] = useState(null);
  const modalControlObj = {
    setPastyBeingEdited,
    setEditingPastyIdx,
    setEditingTabIdx,
    setModalOpen,
  };
  const [activeTab, setActiveTab] = useState(0);
  const [modalStyle] = React.useState(getModalStyle);

  const setUserObject = (newUserObject) => {
    // console.log("set user object ran updatedAt reset");
    const now = new Date();
    newUserObject.updatedAt = now;
    setDisplayObject(newUserObject);
    setUserObjectBase(newUserObject);
  };

  const setUserDataFromAutosave = (newUserObject) => {
    setDisplayObject(newUserObject);
    setUserObjectBase(newUserObject);
  };

  // const MINUTE_MS = 60000;
  const SECOND_MS = 1000;

  // this is the autosync feature
  useEffect(() => {
    if (localStorage.token) {
      const interval = setInterval(() => {
        autosave(userObject, setUserDataFromAutosave);
      }, SECOND_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  });

  // if(null){setOnline(true)}
  if (null) {
    setOnline(false);
  } // TODO: delete this when we actually use it}
  useEffect(() => {
    if (online) {
      // TODO: this is gonna mess us up when we try to do actual online functionality again :(
      loadUserData(userName, setHasGetRun, "server").then(
        (clipboardResponse) => {
          setUserDataFromAutosave(clipboardResponse);
          setHasGetRun(true);
        }
      );
    } else {
      loadUserData(userName, setHasGetRun, "localStorage").then(
        (clipboardResponse) => {
          setUserDataFromAutosave(clipboardResponse);
          setHasGetRun(true);
        }
      );
    }
  }, [online]);

  useEffect(() => {
    if (hasGetRun) {
      saveUserData(userObject, userName, "localStorage");
      // saveUserData(userObject, userName, 'server')
    }
  }, [userObject, hasGetRun]);

  const saveDataToFile = () => {
    saveUserData(userObject, userName, "file");
  };

  const loadTutorial = () => {
    setUserObject(newUserObject);
  };

  const clearData = () => {
    setUserObject({
      ...emptyUserObject,
      tabs: [
        {
          displayName: "Default Title",
          tags: [],
          theme: "",
          type: "",
          pasties: [],
        },
      ],
    });
  };

  const addTab = () => {
    const tempTab = { ...emptyTab };
    tempTab.pasties = []; // This saves us from doing a deep copy but probably won't scale if we wanna get weird later
    const tempUserObject = { ...userObject };
    tempUserObject.tabs.push(tempTab);
    setUserObject(tempUserObject);
  };

  const updatePasty = (pasty, tabIdx, pastyIdx) => {
    setModalOpen(false);
    const tempUserObject = { ...userObject };
    tempUserObject.tabs[tabIdx].pasties[pastyIdx] = pasty;
    setUserObject(tempUserObject);
    setEditingPastyIdx(null);
    setEditingTabIdx(null);
    // setPastyBeingEdited(null);
  };
  const cancelUpdatePasty = () => {
    setModalOpen(false);
    setEditingPastyIdx(null);
    setEditingTabIdx(null);
    // setPastyBeingEdited(null);
  };
  const handleClose = () => {
    setModalOpen(false);
  };
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      // minHeight: "90vh",
      // minWidth: "90vh",
      width: "100%",
      height:"100%",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      // minHeight: "90vh",
      // minWidth: "90vh",
      width: "100%",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  const classes = useStyles();

  const fileOperationsObj = {
    saveDataToFile,
    loadTutorial,
    clearData,
    LoadfileThingie,
  };

  return (
    <>
      <Dialog
        style={modalStyle}
        className={classes.modal}
        open={modalOpen}
        onBackdropClick={handleClose}
        BackdropComponent={Backdrop}
        onClose={() => handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        // fullScreen={true}
        // fullWidth={true}
        maxWidth="xl"
      >
        <ModalPastyForm
          cancelUpdatePasty={cancelUpdatePasty}
          pasty={pastyBeingEdited}
          editingPastyIdx={editingPastyIdx}
          editingTabIdx={editingTabIdx}
          updatePasty={updatePasty}
          handleClose={handleClose}
          fullscreen
        />
      </Dialog>
      <AppBar
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        online={online}
        setOnline={setOnline}
        fileOperationsObj={fileOperationsObj}
        setUserObject={setUserObject}
        searchFilterUserObject={searchFilterUserObject}
        userObject={userObject}
        setDisplayObject={setDisplayObject}
      />

      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        userObject={displayObject}
        displayObject={displayObject}
        setUserObject={setUserObject}
        modalControlObj={modalControlObj}
        addTab={addTab}
      />
    </>
  );
};

export default Main;
