import React from "react";
import { Input } from "@material-ui/core";

const Loadfile = (props) => {
  let fileReader;
  const handleFileRead = (e) => {
    const content = fileReader.result;
    // do something with content
    const userObject = JSON.parse(content);
    props.setUserObject(userObject);
  };
  const handleFileChosen = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };
  return (
    <div className="upload-usertabs">
      <Input
        type="file"
        id="file"
        className="input-file"
        accept=".json"
        onChange={(e) => handleFileChosen(e.target.files[0])}
        name="upload_file"
        style={{ display: "none" }}
      />
      <div onClick={() => document.getElementById("file").click()}>Load from file</div>
    </div>
  );
};

export default Loadfile;

//big thanks to this article https://medium.com/@ilonacodes/front-end-shorts-how-to-read-content-from-the-file-input-in-react-17f49b293909
