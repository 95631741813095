import React, { useState } from "react";
import "./modalPastyForm.scss";
import { ReactComponent as CancelIcon } from "../resources/icons/cancel.svg";
import { TextField } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const ModalPastyForm = (props) => {
  const updatePasty = props.updatePasty;

  const [displayName, setDisplayName] = useState(props.pasty.displayName);
  const [tags, setTags] = useState(props.pasty.tags.join(" "));
  //   const [theme, setTheme] = useState(props.pasty.theme)
  //   const [type, setType] = useState(props.pasty.type)
  const [content, setContent] = useState(props.pasty.content);

  const handleSubmit = (e) => {
    e.preventDefault();
    const tempPasty = { ...props.pasty };
    tempPasty.displayName = displayName;
    tempPasty.tags = tags.split(" ");
    tempPasty.content = content;
    updatePasty(tempPasty, props.editingTabIdx, props.editingPastyIdx);
    props.cancelUpdatePasty();
  };
  const handleChangeDisplayName = (e) => {
    let newDisplayName = e.target.value;
    setDisplayName(newDisplayName);
  };
  const handleChangeTags = (e) => {
    let newTags = e.target.value; // Split them into an array
    setTags(newTags);
  };
  const handleChangeContent = (e) => {
    let newContent = e.target.value;
    setContent(newContent);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    props.cancelUpdatePasty();
  };
  return (
    <>
      <DialogTitle id="form-dialog-title">Edit Pastie</DialogTitle>
      <DialogContent
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSubmit(e);
          }
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          id="label"
          onChange={handleChangeDisplayName}
          type="text"
          value={displayName}
          fullWidth
          label="Label"
        />
        <TextField
          autoFocus
          margin="dense"
          id="label"
          onChange={handleChangeTags}
          type="text"
          value={tags}
          fullWidth
          label="Tags"
        />
        <TextField
          autoFocus
          margin="dense"
          id="label"
          onChange={handleChangeContent}
          type="text"
          value={content}
          fullWidth
          multiline
          label="Content"
        />
        <DialogActions>
          <Button onClick={(e) => handleSubmit(e)}>Submit</Button>
          <Button type="button" onClick={(e) => handleCancel(e)}>
            <CancelIcon width="25px" fill="red" />
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  );
};

export default ModalPastyForm;
