import React, { useEffect, useState } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@mui/icons-material/Clear";
import MoreIcon from "@material-ui/icons/MoreVert";
import { ReactComponent as OnlineIcon } from "../resources/icons/online.svg";
import { ReactComponent as OfflineIcon } from "../resources/icons/offline.svg";
import patchUserObject from "../interfaces/patchUserObject";
import getUserObject from "../interfaces/getUserObject";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    // width: "100%",
    width:"auto",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
    //   width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function PrimarySearchAppBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fileMenuAnchor, setFileMenuAnchor] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isFileMenuOpen = Boolean(fileMenuAnchor);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { saveDataToFile, loadTutorial, clearData, LoadfileThingie } =
    props.fileOperationsObj;
  const setUserObject = props.setUserObject;
  const searchFilterUserObject = props.searchFilterUserObject;
  const [searchText, setSearchText] = useState("");
  const userObject = props.userObject;
  const setDisplayObject = props.setDisplayObject;
  let activeTab = props.activeTab;
  const setActiveTab = props.setActiveTab;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFileMenuOpen = (e) => {
    setFileMenuAnchor(e.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleFileMobileMenuClose = () => {
    // setFileMobileMenuAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleFileMenuClose = () => {
    setFileMenuAnchor(null);
    handleFileMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    // const filteredObj = searchFilterUserObject(searchText, userObject);
    // setDisplayObject(filteredObj);
  };
  useEffect(() => {
    const filteredObj = searchFilterUserObject(searchText, userObject);
    setDisplayObject(filteredObj);
    try {
      if (filteredObj.tabs[activeTab].visible === false) {
        const isVisible = (element) => element.visible;
        console.log(filteredObj.tabs.findIndex(isVisible));
        setActiveTab(filteredObj.tabs.findIndex(isVisible));
      }
    } catch {
      console.log({ filteredObj, activeTab });
    }
  }, [
    userObject,
    searchText,
    setDisplayObject,
    searchFilterUserObject,
    setActiveTab,
    activeTab,
  ]);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={(e) => {
          handleMenuClose(e);
          window.location.href = "/signup";
        }}
      >
        Login/Signup
      </MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>
        Go {props.online ? "offline" : "online"}
      </MenuItem> */}
      <MenuItem
        onClick={(e) => {
          handleMenuClose(e);
          patchUserObject({});
        }}
      >
        Manual save
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          handleMenuClose(e);
          getUserObject().then((data) => setUserObject(data));
        }}
      >
        Manual load
      </MenuItem>
    </Menu>
  );

  const renderFileMenu = (
    <Menu
      anchorEl={fileMenuAnchor}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isFileMenuOpen}
      onClose={handleFileMenuClose}
    >
      <MenuItem
        onClick={(e) => {
          handleFileMenuClose(e);
          saveDataToFile();
        }}
      >
        Save To File
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          handleFileMenuClose(e);
        }}
      >
        <LoadfileThingie setUserObject={props.setUserObject} />{" "}
        {/* This component is a bit hairy due to html/react/js overlap */}
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          handleFileMenuClose(e);
          loadTutorial();
        }}
      >
        Load Tutorial
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          handleFileMenuClose(e);
          clearData();
        }}
      >
        Clear all tabs
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleFileMenuOpen}>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge /*badgeContent={4}*/ color="secondary">
            <SaveIcon />
          </Badge>
        </IconButton>
        <p>File Operations</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          {props.online ? (
            <OnlineIcon fill="currentColor" className="statusIcon online" />
          ) : (
            <OfflineIcon fill="currentColor" className="statusIcon offline" />
          )}
        </IconButton>
        <p>Cloud Actions</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar scrollButtons={true}>
          <Typography className={classes.title} variant="h6" noWrap>
            Bucket Pasta
          </Typography>
          <div className={classes.search}>
            <InputBase
              placeholder="Search…"
              value={searchText}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={handleSearchChange}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<SearchIcon className={classes.searchIcon} />}
              endAdornment={
                <IconButton
                  aria-label="clear"
                  onClick={() => setSearchText("")}
                >
                  <ClearIcon />
                </IconButton>
              }
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label="show 4 new mails"
              color="inherit"
              onClick={handleFileMenuOpen}
            >
              <Badge /*badgeContent={4} */ color="secondary">
                <SaveIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {props.online ? (
                <OnlineIcon fill="currentColor" className="statusIcon online" />
              ) : (
                <OfflineIcon
                  fill="currentColor"
                  className="statusIcon offline"
                />
              )}
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderFileMenu}
    </div>
  );
}
