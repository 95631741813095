// accept search text
// accept userObject
// return displayObject

const deepCopy = (object) => {
  return JSON.parse(JSON.stringify(object));
};

// const searchFilterUserObject = (searchText, userObject) => {
//   searchText = searchText.toLowerCase();
//   const displayObject = deepCopy(userObject);
//   displayObject.tabs = [];
//   //   console.log({ searchText, userObject, displayObject });
//   // which tabs are included?
//   // If the tab displayname or tags match then display whole tab

//   const tabs = [];
//   // add matching tabs in entirety
//   userObject.tabs.forEach((tab) => {
//     if (
//       tab.displayName.toLowerCase().includes(searchText) ||
//       tab.tags.map((tag) => tag.toLowerCase()).includes(searchText)
//     ) {
//       tabs.push(JSON.parse(JSON.stringify(tab)));
//     } else {
//       // check tab for matching pasties and if they are included then put the whole tab up
//       const tempTab = JSON.parse(JSON.stringify(tab));
//       console.log(tab);
//       console.log(tempTab);
//       tempTab.pasties = [];
//       tab.pasties.forEach((pasty) => {
//         if (
//           pasty.displayName.toLowerCase().includes(searchText) ||
//           pasty.content.toLowerCase().includes(searchText)
//         ) {
//           tempTab.pasties.push(pasty);
//         }
//       });
//       if (tempTab.pasties.length) {
//         tabs.push(tempTab);
//       }
//     }
//   });

//   displayObject.tabs = tabs;

//   return displayObject;
// };

const searchFilterUserObject = (searchText, userObject) => {
  searchText = searchText.toLowerCase();
  const displayObject = deepCopy(userObject);
  displayObject.tabs.forEach((tab) => {
    tab.visible = false;
    tab.pasties.forEach((pasty) => {
      if (
        pasty.content.toLowerCase().includes(searchText) ||
        pasty.displayName.toLowerCase().includes(searchText) ||
        pasty.tags.reduce((acc, curr) => {
          return acc || curr.toLowerCase().includes(searchText);
        }, false)
      ) {
        pasty.visible = true;
        tab.visible = true;
      } else {
        pasty.visible = false;
      }
    });
    if (
      tab.displayName.toLowerCase().includes(searchText)
      // || tab.tags.contains(searchText)
    ) {
      tab.visible = true;
      tab.pasties.forEach((pasty) => (pasty.visible = true));
    }
  });
  return displayObject;
};

export default searchFilterUserObject;
