import loadFromServer from "./loadFromServer.js";
import loadFromLocalStorage from "./loadFromLocalStorage.js";
import loadFromFile from "./loadFromFile";

const loadInterface = (userName, setHasGetRun, loadFromWhere) =>
  new Promise((res, rej) => {
    try {
      switch (loadFromWhere) {
        case "server":
          loadFromServer(userName).then((response) => {
            res(response);
          });
          break;
        case "localStorage":
          res(loadFromLocalStorage());
          break;
        case "file":
          loadFromFile();
          break;
        default:
          rej("save location not specified correctly");
      }
    } catch {
      // console.log({ userName, setHasGetRun, loadFromWhere });
    }
  });

export default loadInterface;
