import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";

import ListItem from "./listItem.jsx";
// import "./list.scss";

const ListComponent = (props) => {
  let pasties = props.pasties;
  let tabNumber = props.tabNumber;
  let deleteItem = props.deleteItem;
  let updateItem = props.updateItem;
  let swap = props.swap;

  const [dragId, setDragId] = useState("meow");

  const handleDrag = (e) => {
    setDragId(e.currentTarget.id);
  };

  const handleDrop = (e) => {
    let dragBox = dragId; // should be an int of the idx of the box being dragged
    let dropBox = e.currentTarget.id;
    swap(dragBox, dropBox);
  };
  const classes = useStyles();
  // const [dense, setDense] = React.useState(false);
  // const [secondary, setSecondary] = React.useState(false);
  const dense = false;
  return (
    <div className={classes.root}>
      <Grid item xs={12} md={6}>
        <div className={classes.demo}>
          <List dense={dense}>
            {pasties.map((item, clipIdx) => {
              return (
                <ListItem
                  key={clipIdx}
                  pastie={item}
                  clipIdx={clipIdx}
                  tabNumber={tabNumber}
                  deleteItem={deleteItem}
                  updateItem={updateItem}
                  handleDrag={handleDrag}
                  handleDrop={handleDrop}
                  modalControlObj={props.modalControlObj}
                />
              );
            })}
          </List>
        </div>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default ListComponent;
