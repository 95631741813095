import React, { useState } from "react";
import emptyPastie from "../resources/emptyPastie.json";
import List from "./list.jsx";
import Form from "./form.jsx";
import "./tab.scss";
import { TextField } from "@material-ui/core";
// import { IconButton } from "@material-ui/core";
// import { ReactComponent as EditIcon } from "../resources/icons/edit.svg";
import { Typography } from "@material-ui/core";
// username is for future feature, placeholder

const TabComponent = (props) => {
  // Mapping tabs as a property in the userObject
  let tab = props.tab;
  let userObject = props.userObject;
  let setUserObject = props.setUserObject;
  let tabNumber = props.tabNumber;

  const [editTitle, setEditTitle] = useState(false);

  let swap = (oldPosition, newPosition) => {
    let old = userObject.tabs[tabNumber].pasties[oldPosition];
    let neww = userObject.tabs[tabNumber].pasties[newPosition];
    const changedObject = { ...userObject };
    changedObject.tabs[tabNumber].pasties[oldPosition] = neww;
    changedObject.tabs[tabNumber].pasties[newPosition] = old;
    setUserObject(changedObject);
  };

  let addOneItemToClipboard = (newItem, tabNumber) => {
    let changedObject = { ...userObject };
    let newItemObj = { ...emptyPastie, content: newItem };
    changedObject.tabs[tabNumber].pasties.unshift(newItemObj);
    setUserObject(changedObject);
  };

  let updateOneItem = (updatedItemTabIdx, updatedItemIdx, updatedItemText) => {
    let changedObject = { ...userObject };
    changedObject.tabs[updatedItemTabIdx].pasties[updatedItemIdx].content =
      updatedItemText;
    setUserObject(changedObject);
  };

  let removeOneItemFromClipboard = (removedItemTabIdx, removedItemIdx) => {
    let changedObject = { ...userObject };
    let modifiedClipboard = changedObject.tabs[
      removedItemTabIdx
    ].pasties.filter((item, idx) => idx !== removedItemIdx);
    changedObject.tabs[removedItemTabIdx].pasties = modifiedClipboard;
    setUserObject(changedObject);
  };

  const renameTab = (e) => {
    const newName = e.target.value;
    let tempTab = { ...tab };
    tempTab.displayName = newName;
    let tempUserObject = { ...userObject };
    tempUserObject.tabs[tabNumber].displayName = newName;
    setUserObject(tempUserObject);
  };

  const catchTitleEditEnd = (e) => {
    if (e.key === "Enter") {
      setEditTitle(false);
    }
  };

  return tab.visible ? (
    <div key={tabNumber} className="tab">
      <Form
        formHandler={addOneItemToClipboard}
        tabNumber={tabNumber}
        placeholder={"Type or paste content here then hit enter"}
        // display="inline"
      />
      {editTitle ? (
        <TextField
          autoFocus
          margin="dense"
          id="label"
          onChange={renameTab}
          onKeyPress={catchTitleEditEnd}
          type="text"
          value={tab.displayName}
          fullWidth
          label="Tab Title"
        />
      ) : (
        <>
          <Typography
            variant="h3"
            // onMouseEnter={handlePopoverOpen}
            // onMouseLeave={handlePopoverClose}
            onClick={() => setEditTitle(true)}
          >
            {tab.displayName}
          </Typography>
          {/* <IconButton
            // edge="left"
            aria-label="edit"
            // style={titleEditButtonStyle}
            >
            <EditIcon width="25px" />
          </IconButton> */}
        </>
      )}
      <List
        deleteItem={removeOneItemFromClipboard}
        updateItem={updateOneItem}
        pasties={tab.pasties}
        tabNumber={tabNumber}
        swap={swap}
        modalControlObj={props.modalControlObj}
      />
    </div>
  ) : null;
};

export default TabComponent;
