import SelfTab from "../components/tab";
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons"; // todo get a plus icon I own

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const activeTab = props.activeTab;
  const setActiveTab = props.setActiveTab;
  const userTabInfo = props.displayObject.tabs;
  const userObject = props.userObject;
  const setUserObject = props.setUserObject;
  const modalControlObj = props.modalControlObj;
  const displayObject = props.displayObject;

  const handleChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" setActiveTab={setActiveTab}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="simple tabs example"
          selectionFollowsFocus={false}
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          // scrollButtons={true}
          // allowScrollButtonsMobile
        >
          {userTabInfo.map((tab, idx) =>
            tab.visible ? (
              <Tab label={tab.displayName} value={idx} key={idx} />
            ) : null
          )}
          <Button color="inherit">
            <Add onClick={() => props.addTab()} />
          </Button>
        </Tabs>
      </AppBar>
      {userTabInfo.map((tab, idx) => {
        return tab.visible ? (
          <TabPanel value={activeTab} index={idx} key={idx}>
            <SelfTab
              tab={tab}
              userObject={userObject}
              displayObject={displayObject}
              setUserObject={setUserObject}
              tabNumber={idx}
              key={idx}
              modalControlObj={modalControlObj}
            />
          </TabPanel>
        ) : null;
      })}
    </div>
  );
}
