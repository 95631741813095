import React, { useState } from "react";
import "./form.scss";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const FormComponent = (props) => {
  let defaultValue = props.value;
  let placeholder = props.placeholder;
  let tabNumber = props.tabNumber;
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e) => {
    let newClipboardItem = e.target.value;
    setValue(newClipboardItem);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      props.formHandler(value, tabNumber);
      setValue("");
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  }));

  const classes = useStyles();

  return (
    <TextField
      id="outlined-full-width"
      label="New Pastie"
      style={{ margin: 8 }}
      placeholder={placeholder}
      className={classes.root}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      value={value}
      fullWidth
      multiline
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
    />
  );
};

export default FormComponent;
