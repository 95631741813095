import axios from "axios";
import getUserObject from "./getUserObject";

const signin = (email, password) => {
  const baseRoute = "https://bucket-pasta-server-piwii.ondigitalocean.app";
  const data = { username: email, password: password };
  axios.post(`${baseRoute}/signin`, data).then((response) => {
    localStorage.setItem("token", response.data.token);
    getUserObject().then((data) => {
      // console.log("this in in the then statement for login");
      window.location = "/";
    });
  });
};

export default signin;
