import React from "react";
import copyToClipboard from "./copyToClipboard.js";
// import Form from "./form.jsx";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";

import { ReactComponent as CopyIcon } from "../resources/icons/copy.svg";
import { ReactComponent as EditIcon } from "../resources/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../resources/icons/delete.svg";

const ListItemComponent = (props) => {
  let tabNumber = props.tabNumber;
  let clipidx = props.clipIdx;
  let deleteItem = props.deleteItem;
  let handleDrag = props.handleDrag;
  let handleDrop = props.handleDrop;
  const {
    setPastyBeingEdited,
    setEditingPastyIdx,
    setEditingTabIdx,
    setModalOpen,
  } = props.modalControlObj;
  const middlePerson = (value) => {
    setModalOpen(true);
    setPastyBeingEdited(props.pastie);
    setEditingTabIdx(tabNumber);
    setEditingPastyIdx(clipidx);
  };

  return props.pastie.visible ? (
    <ListItem
      className="listItems"
      draggable="true"
      onDragStart={handleDrag}
      onDrop={handleDrop}
      ontouchstart={handleDrag}
      ontouchend={handleDrop}
      onDragOver={(e) => e.preventDefault()}
      clipidx={clipidx}
      id={clipidx}
    >
      <ListItemText
        primary={props.pastie.displayName}
        secondary={
          props.pastie.content.length > 75
            ? props.pastie.content.substring(0, 75) + "..."
            : props.pastie.content
        }
      />

      <IconButton
        // edge="middle"
        aria-label="copy"
        onClick={() => copyToClipboard(props.pastie.content)}
      >
        <CopyIcon width="25px" />
      </IconButton>

      <IconButton
        // edge="left"
        aria-label="edit"
        onClick={() => middlePerson()}
      >
        <EditIcon width="25px" />
      </IconButton>

      <IconButton
        // edge="right"
        aria-label="delete"
        onClick={() => deleteItem(tabNumber, clipidx)}
      >
        <DeleteIcon width="25px" />
      </IconButton>
    </ListItem>
  ) : null;
};

export default ListItemComponent;

/*

*/
